
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Presentation",
})
export default class Presentation extends Vue {
  // ...
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Présentation");
  }
  fadeLeft(): void {
    document.getElementById("title-div")?.classList.add("fade-out-left");
    // listen fade-out-left animation end
    document
      .getElementById("title-div")
      ?.addEventListener("animationend", () => {
        document.getElementById("title-div")?.remove();
        // display presentation-div

        document
          .getElementById("presentation-div")
          ?.classList.add("fade-in-right");
        document.getElementById("presentation-div")?.classList.remove("hide");
        document.getElementById("presentation-div")?.classList.remove("hidden");
      });
  }
  goToCuriculumVitaePage(): void {
    document.getElementById("presentation-div")?.classList.add("fade-out-left");
    document
      .getElementById("presentation-div")
      ?.addEventListener("animationend", () => {
        this.$router.push("/curiculum-vitae");
        this.$store.commit("setCurrentPageTitle", "Curiculum Vitae");
      });
  }
  mounted(): void {
    // listen to right arrow input and navigate to /skills
    document.addEventListener("keydown", (event) => {
      if (event.key === "ArrowRight") {
        this.goToCuriculumVitaePage();
      }
    });
  }
}
